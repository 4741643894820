// @ts-nocheck
import Vue from 'vue'
import Router from 'vue-router'
import { beforeEach, nonLangPageRedirect } from '@xpedeo/core'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/index.html',
      name: 'Splash',
      component: () => import(/* webpackChunkName: "splash-screen" */ '@xpedeo/core/dist/Splash'),
      alias: '/'
    },
    {
      path: '/dock',
      name: 'Dock',
      component: () => import(/* webpackChunkName: "dock-screen" */ '@xpedeo/core/dist/Dock')
    },
    {
      path: '/page/:id',
      redirect: nonLangPageRedirect
    },
    {
      path: '/:lang',
      component: { render: h => h('router-view') },
      children: [
        {
          path: '',
          redirect: '/'
        },
        {
          path: 'page/:id',
          name: 'Page',
          component: () => import(/* webpackChunkName: "page-screen" */ '@xpedeo/core/dist/PageContainer')
        }]
    }
  ]
})

router.beforeEach((to, from, next) => {
  beforeEach(to, from, next)
})

export default router
